#about {
  padding: 0 48px;
}

#container-about-a {
  width: 100%;
  margin-bottom: 4vh;
  height: 100%;
  // display: flex;
}

#container-about-a-info {
  height: fit-content;
  width: 100%;
  margin-bottom: 4vh;
  display: flex;
  flex-direction: row;
}

// .img-rolling-ball {
//   width: 400px;
//   height: 400px;
//   float: right;
//   margin-left: 64px;
//   margin-bottom: 24px;
//   border-radius: 50%;
//   shape-outside: circle();
//   background-position: center;
//   background-size: cover;
// }

#img-about-me {
  // object-fit: cover;
  // object-position: center;
  margin: 24px 24px 24px 0;
  min-width: 400px;
  background-position: center;
  background-size: cover;
}

// #container-about-a-img {
//   min-width: 40%;
//   height: fit-content;
//   margin-left: 24px;
//   float: right;
//   border-radius: 50%;
//   shape-outside: circle();
//   overflow: hidden;
//   clip-path: circle();
//   border: solid 8px black;
//   img {
//     width: 100%;
//     height: auto;
//   }
// }

#container-about-a-skills {
  margin: 48px 0px;
}

#container-about-b {
  // height: 80vh;
  display: flex;
  width: 100%;
}

#container-about-b-info {
  padding: 0;
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4vh;
  // justify-content: space-between;
  h2 {
    padding: 0;
    margin: 0;
    height: fit-content;
    overflow: hidden;
  }
}

#container-about-b-info-btn {
  button {
    padding: 18px 40px;
    position: relative;
    z-index: 3;
  }
}

#container-about-b-hats {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  img {
    cursor: pointer;
    margin: 0;
    width: 100%;
  }
  #img-click {
    width: 60%;
    margin-bottom: -16%;
  }
}


@media (max-width:1600px) {
  #container-about-b-info {
    width: 48%;
  }
}

@media (max-width:1200px) {
  #img-about-me {
    min-width: 360px;
  }

  #container-about-b {
    flex-direction: column;
  }

  #container-about-b-info {
    width: 100%;
    // flex-direction: row;
  }

  #container-about-b-info-btn {
    // display: flex;
    // justify-content: center;
    button {
      padding: 18px 40px;
    }
  }

  #container-about-b-hats {
    width: 60%;
    margin-top: -12%;
    margin-left: 40%;
  }
}

@media (max-width:992px) {
  #img-about-me {
    min-width: 300px;
  }
  #container-about-b-hats {
    width: 70%;
    margin-left: 30%;
  }
}

@media (max-width:768px) {
  #about {
    padding: 24px 24px 0px 24px;
  }
  #container-about-a-info {
    flex-direction: column;
    padding: 0;
    p {
      width: 100%;
    }
  }
  #img-about-me {
    min-width: 100%;
    height: 240px;
    margin: 0;
    div {
      background-position: center -120px !important;
    }
  }
  #container-about-b-hats {
    width: 80%;
  }
  #container-about-b-info-btn {
    button {
      padding: 12px 24px;
    }
  }

}

@media (max-width:576px) {
  #img-about-me {
    height: 24vh;
    div {
      background-position: center -80px !important;
    }
  }
  #container-about-b-hats {
    width: 100%;
    margin-top: 4%;
    margin-left: 0;
  }
  #container-about-b-info {
    width: 100%;
    h2 {
      text-align: center;
    }
  }
  #container-about-b-info-btn {
    display: flex;
    justify-content: center;
  }

}

@media (max-width:480px) {
  #img-about-me {
    div {
      background-position: center -40px !important;
    }
  }
}

@media (max-width:360px) {
  #container-about-b-info-btn {
    button {
      padding: 12px 32px;
    }
  }

}
