.loading {
  width: 100vw;
  height: 100vh;
  background-color: #7DC6CC;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  img {
    width: 40vw;
    max-width: 880px;
    height: auto;
  }
}

@keyframes disappear {
  from{
    clip-path: circle(50% at 50% 50%);
  }
  to{
    clip-path: circle(0% at 50% 50%);
  }
}

.loading-complete {
  animation: disappear 0.4s forwards ease-out;
}

@media (max-width: 768px) {
  .loading img {
    width: 60vw;
  }
}
