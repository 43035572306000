.btn-white-rounded {
  border-radius: 100px;
  padding: 12px 32px;
  margin: 48px 0;
  color: white;
  background-color: transparent;
  border: 2px solid #fff;
  transition: all .3s ease;
  font-family: 'Source Sans Pro';
  font-size: 1.5rem;
  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: black;
  }
}

.btn-black-rounded {
  border-radius: 100px;
  padding: 12px 32px;
  margin: 48px 0;
  color: black;
  background-color: transparent;
  border: 2px solid #000;
  transition: all .3s ease;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;

  &:hover {
    cursor: pointer;
    background-color: #000;
    color: white;
  }

  &:disabled {
    color: #999; // Lighter color to indicate it's disabled
    border: 2px solid #999; // Border color to match the text color
    background-color: transparent;
    cursor: not-allowed; // Cursor to indicate the button is not clickable

    &:hover {
      background-color: transparent; // Ensure background doesn't change on hover
      color: #999; // Text color remains the same on hover
    }
  }
}


@media (max-width: 1600px) {
  .btn-white-rounded {
    font-size: 1.4rem;
    padding: 12px 28px;
  }
  .btn-black-rounded {
    font-size: 1.4rem;
    padding: 12px 28px;
  }
}

@media (max-width: 1200px) {
  .btn-white-rounded {
    font-size: 1.3rem;
  }
  .btn-black-rounded {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .btn-white-rounded {
    font-size: 1.2rem;
    padding: 12px 24px;
  }
  .btn-black-rounded {
    font-size: 1.2rem;
    padding: 12px 24px;
  }
}

@media (max-width: 576px) {
  .btn-white-rounded {
    font-size: 1.1rem;
    margin: 24px 0;
  }
  .btn-black-rounded {
    font-size: 1.1rem;
    margin: 24px 0;
  }
}

@media (max-width: 400px) {
  .btn-white-rounded {
    font-size: 1rem;
    padding: 8px 20px;
  }
  .btn-black-rounded {
    font-size: 1rem;
    padding: 8px 20px;
  }
}
