body {
  margin: 0;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Right Grotesk';
  src: url('../fonts/RightGrotesk-CompactDark.otf');
}

@font-face {
  font-family: 'Right Grotesk Medium';
  src: url('../fonts/PPRightGrotesk-Medium.otf');
}

.container-max-size {
  max-width: 1800px;
  margin: 0 auto;
}

.container-header {
  padding: 0;
  margin: 0;
  height: fit-content;
  overflow: hidden;
  width: fit-content;
}

.header-lg {
  font-family: 'Right Grotesk';
  font-size: 7rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: normal;
}

.header-md {
  font-family: 'Right Grotesk';
  font-size: 5.7rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: normal;
}

.header-sm {
  font-family: 'Right Grotesk';
  font-size: 4.5rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: normal;
}

.subheader-lg {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 3.5rem;
  font-weight: 400;
  margin: 0;
}

.subheader-md {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
}

.text-bold {
  font-family: 'Right Grotesk Medium';
  font-size: 2.2rem;
}

.text-bold-sm {
  font-family: 'Right Grotesk Medium';
  font-size: 1.8rem;
}

.text-hollow {
  -webkit-text-stroke: 2px #FCFCFC;
  color: transparent;
  display: inline-block;
}

.text-body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.9rem;
  font-weight: 400;
}

.text-body-sm {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
}

.text-white {
  color: #FCFCFC;
}

.text-blue {
  color: #7DC6CC;
}

.app-container {
  // Your container styles here...
  // Background color will be set inline based on the active section.
  transition: background-color 0.3s; // Optional: for smooth background color transition
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  margin: 0 0 92px 0;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.section-container {
  max-width: 1800px;
  width: 100%;
  overflow: hidden;
}

@media (max-width:1600px) {
  .header-lg {
    font-size: 6.4rem;
  }
  .header-md {
    font-size: 5.5rem;
  }
  .header-sm {
    font-size: 4.3rem;
  }
  .subheader-lg {
    font-size: 3.25rem;
  }
  .subheader-md {
    font-size: 1.9rem;
  }
  .text-bold {
    font-size: 2rem;
  }
  .text-bold-sm {
    font-size: 1.6rem;
  }
  .text-body {
    font-size: 1.7rem;
  }
  .text-body-sm {
    font-size: 1.4rem;
  }
}

@media (max-width:1200px) {
  .header-lg {
    font-size: 6.2rem;
  }
  .header-md {
    font-size: 5rem;
  }
  .header-sm {
    font-size: 4rem;
  }
  .subheader-lg {
    font-size: 3.1rem;
  }
  .subheader-md {
    font-size: 1.8rem;
  }
  .text-bold {
    font-size: 1.8rem;
  }
  .text-body-sm {
    font-size: 1.5rem;
  }
  .text-body {
    font-size: 1.7rem;
  }
  .text-body-sm {
    font-size: 1.4rem;
  }
}

@media (max-width:992px) {
  .header-lg {
    font-size: 6rem;
  }
  .header-md {
    font-size: 4.5rem;
  }
  .header-sm {
    font-size: 3.5rem;
  }
  .subheader-lg {
    font-size: 2.9rem;
  }
  .subheader-md {
    font-size: 1.7rem;
  }
  .text-bold {
    font-size: 1.6rem;
  }
  .text-bold-sm {
    font-size: 1.4rem;
  }
  .text-body {
    font-size: 1.6rem;
  }
  .text-body-sm {
    font-size: 1.3rem;
  }
}

@media (max-width:768px) {
  .header-lg {
    font-size: 5.4rem;
  }
  .header-md {
    font-size: 4rem;
  }
  .header-sm {
    font-size: 3.2rem;
  }
  .subheader-lg {
    font-size: 2.5rem;
  }
  .subheader-md {
    font-size: 1.6rem;
  }
  .text-bold {
    font-size: 1.6rem;
  }
  .text-bold-sm {
    font-size: 1.3rem;
  }
  .text-body {
    font-size: 1.5rem;
  }
  .app-container {
    margin-bottom: 56px;
  }
}

@media (max-width:576px) {
  .header-lg {
    font-size: 5.3rem;
  }
  .header-md {
    font-size: 3.5rem;
  }
  .header-sm {
    font-size: 2.8rem;
  }
  .subheader-lg {
    font-size: 2.3rem;
  }
  .subheader-md {
    font-size: 1.5rem;
  }
  .text-bold {
    font-size: 1.5rem;
  }
  .text-bold-sm {
    font-size: 1.2rem;
  }
  .text-body {
    font-size: 1.4rem;
  }
}

@media (max-width:480px) {
  .header-lg {
    font-size: 4.6rem;
  }
  .header-md {
    font-size: 2.8rem;
  }
  .header-sm {
    font-size: 2.4rem;
  }
  .subheader-lg {
    font-size: 2.1rem;
  }
  .subheader-md {
    font-size: 1.4rem;
  }
  .text-bold {
    font-size: 1.4rem;
  }
  .text-bold-sm {
    font-size: 1.1rem;
  }
  .text-body {
    font-size: 1.3rem;
  }
  .text-body-sm {
    font-size: 1.2rem;
  }
}

@media (max-width:360px) {
  .header-lg {
    font-size: 4rem;
  }
  .header-md {
    font-size: 2.5rem;
  }
  .header-sm {
    font-size: 2rem;
  }
  .subheader-lg {
    font-size: 1.9rem;
  }
  .subheader-md {
    font-size: 1.3rem;
  }
  .text-bold {
    font-size: 1.3rem;
  }
  .text-bold-sm {
    font-size: 1rem;
  }
  .text-body {
    font-size: 1.2rem;
  }
  .text-body-sm {
    font-size: 1.1rem;
  }
}
