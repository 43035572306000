.project-card-mb {
  position: relative;
  cursor: pointer;
  margin: 16px 0;
  width: 100%;
  height: 240px;
  cursor: pointer;
  &.expanded {
    height: 100%;
    margin: 16px 24px;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: default;
    .project-info-mb {
      position: relative;
      z-index: initial;
    }
    .container-img-project-card-mb {
      position: relative;
      z-index: initial;
      height: 360px;
      img {
        filter: grayscale(0%);
      }
    }
  }
}

.project-info-mb {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0 0 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-img-project-card-mb {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  overflow: hidden;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out, filter 0.3s ease-in-out;
  img {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: 1;
  }
  .container-img-project-card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: radial-gradient(rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0.3) 60%);
  }
}

.project-types-mb {
  min-width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    margin: 0 12px;
    font-weight: 500;
  }
}

.arrow-left-mb {
  top: 0;
  margin-top: -24px;
  left: 2vw;
  width: 100px;
  pointer-events: all;
  cursor: pointer;
}

.project-details-mb {
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .client-info {
    display: flex;
  }
}

.container-project-card-link-mb {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.site-link-mb {
  position: relative;
  color: black;
  pointer-events: all;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
  svg {
    width: 24px;
    margin-left: 8px;
  }
  path {
    transition: fill 0.3s ease-in-out;
  }
  &:hover {
    color: #7DC6CC;
    path {
      fill: #7DC6CC;
    }
  }
}

.project-description-mb {
  text-align: center;
}

.tech-stack-mb {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  .tech-icon-mb {
    width: 60px;
    margin: 12px;
  }
}


@media (max-width:1600px) {

}

@media (max-width:1200px) {

}

@media (max-width:992px) {

}

@media (max-width:768px) {

}

@media (max-width:576px) {
  .project-types-mb span {
    margin: 0px 6px;
  }
}

@media (max-width:480px) {

}

@media (max-width:360px) {

}
