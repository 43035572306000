#portfolio {
  padding: 0 48px 24px 48px;
  max-width: 100%;
}

#container-portfolio-a {
  min-width: 100%;
  margin-bottom: 8vh;
  height: 100%;
}

#container-projects {
  width: 100%;
  // min-height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

#container-portfolio-b {
  //white to blue vertical linear gradient background
  // background: linear-gradient(180deg, #FCFCFC 70%, #7DC6CC 100%);
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  margin-bottom: 2vh;
}

#container-portfolio-b-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#container-portfolio-b-eyes {
  display: flex;
  align-items: flex-end;
  padding-bottom: 4vh;
}

.eye {
  position: relative;
  width: 100px; // Eye width
  height: 100px; // Eye height
  //white gradient background
  background: radial-gradient(#FCFCFC 40%, #e8e8e8 60%, #d4d4d4 80%, #c0c0c0 100%);
  border-radius: 50%;
  // soft box shadow
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.5), inset 1px -2px 2px rgba(0, 0, 0, 0.7);
  display: inline-block;
  margin: 0 15px;

  .light {
    z-index: 2;
    width: 46px;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.8);
    right: 8%;
    top: 0%;
    position: absolute;
    clip-path: polygon(1% 39%, 12% 34%, 24% 29%, 38% 26%, 53% 25%, 68% 27%, 81% 30%, 94% 37%, 91% 46%, 72% 45%, 55% 45%, 43% 46%, 30% 47%, 18% 49%, 9% 51%);
    transform: rotate(45deg);
    // box-shadow: 2px 2px 6px rgba(255, 255, 255, 0.5), -2px -2px 6px rgba(255, 255, 255, 0.5);
  }

  .pupil {
    position: relative;
    width: 60px; // Pupil width
    height: 60px; // Pupil height
    background-color: black;
    box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    left: 20%;
    top: 20%;
    transform: translate(-50%, -50%); // Center the pupil
  }

  .ring {
    position: absolute;
    z-index: 1;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.5), inset 1px -2px 2px rgba(0, 0, 0, 0.5);
    border: 1px solid #a7a7a700;
    left: 1px;
    top: 1px;
  }
}

.overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:#FCFCFC;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.wrapper-project-card {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
}

@media (max-width:1600px) {

}

@media (max-width:1200px) {

}

@media (max-width:992px) {

}

@media (max-width:768px) {
  #portfolio {
    padding: 24px 24px 0px 24px;
  }
  #container-portfolio-b {
    height: 30vh;
  }
  #container-btn-portfolio {
    display: flex;
    justify-content: center;
  }
}

@media (max-width:576px) {
  #container-portfolio-b {
    height: 24vh;
  }
}

@media (max-width:480px) {

}

@media (max-width:360px) {

}
