.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 120px;
  background-color: black;
  color: white;
  text-align: center;
  padding: 40px 24px 24px 24px;
  z-index: -1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    color: white;
    text-decoration: none;
    cursor: pointer;
    margin: 0px 36px 0px 0px;
  }
}

.footer-links {
  display: flex;
  align-items: center;
  img {
    width: 40px;
    margin-left: 36px;
  }
  a {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    color: white;
    text-decoration: none;
    cursor: pointer;
    margin-left: 24px;
    &:hover {
      color: #7DC6CC;
    }
    .text-link {
      display: inline;
    }
    .icon-link {
      display: none;
    }
  }

}

@media (max-width: 1600px) {
  .sticky-footer {
    p {
      font-size: 1.4rem;
    }
  }
  .footer-links {
    img {
      width: 36px;
    }
    a {
      font-size: 1.4rem;
    }
  }
}

@media (max-width: 1200px) {
  .sticky-footer {
    p {
      font-size: 1.3rem;
    }
  }
  .footer-links {
    img {
      width: 32px;
    }
    a {
      font-size: 1.3rem;
    }
  }
}

@media (max-width: 768px) {
  .sticky-footer {
    padding: 32px 16px 16px 16px;
    p {
      font-size: 1.2rem;
    }
  }
  .footer-links {
    img {
      width: 28px;
    }
    a {
      font-size: 1.2rem;
      .text-link {
        display: none;
      }
      .icon-link {
        display: inline;
      }
    }
  }
}

@media (max-width: 480px) {
  #home {
    padding: 24px 16px;
  }
  .sticky-footer {
    padding: 16px 0px 16px 0px;
    p {
      margin: 0px 12px 0px 0px;
      font-size: 1rem;
    }
  }
  .footer-links {
    img {
      margin-left: 12px;
    }
    a {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 360px) {
  .sticky-footer {
    p {
      font-size: 0.8rem;
    }
  }
  .footer-links {
    img {
      width: 24px;
    }
    a {
      font-size: 1rem;
    }
  }
}
