.project-card {
  display: flex;
  position: relative;
  cursor: pointer;
  margin: 24px 16px;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out, all 0.3s ease-in-out;
  &:hover {
    .container-img-project-card {
      width: 450px;
      img {
        transform: scale(1.2);
        filter: grayscale(0%);
      }
    }
  }
  &.left {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: left;
    .project-info {
      margin-left: 48px;
    }
  }
  &.right {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: right;
    .project-info {
      margin-right: 48px;
    }
  }
  .header-sm {
    text-wrap: nowrap;
  }
  &.expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: max-content;
    cursor: default;
    .project-info {
      width: 100%;
      margin: 40px 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      h3 {
        font-size: 6rem;
      }
    }
    .project-types {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      span {
        margin: 0 24px;
        font-size: 2rem;
      }
    }
    .container-img-project-card {
      width: 60%;
      height: 480px;
      img {
        transform: scale(1);
        filter: grayscale(0%);
      }
    }
  }
}

.container-img-project-card {
  width: 480px;
  height: 240px;
  position: relative;
  overflow: hidden;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out, filter 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

}


.project-types {
  display: flex;
  flex-direction: column;
  span {
    text-wrap: nowrap;
  }
}

.project-details {
  // min-height: fit-content;
  width: 60%;
  margin: 40px 0 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .client-info {
    display: flex;
  }
}


.arrow-left {
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  top: 4vh;
  left: 8vw;
  width: 200px;
  height: 200px;
  z-index: 3;
  path {
    fill: black;
    transition: fill 0.3s ease-in-out;
  }
  &:hover {
    path {
      fill: #7DC6CC;
    }
  }

}

.container-project-card-link {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.site-link {
  position: relative;
  color: black;
  pointer-events: all;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
  svg {
    width: 36px;
    margin-left: 12px;
  }
  path {
    transition: fill 0.3s ease-in-out;
  }
  &:hover {
    color: #7DC6CC;
    path {
      fill: #7DC6CC;
    }
  }
}

.project-description {
  width: 80%;
  margin: 40px 0;
  text-align: center;
}

.tech-stack {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  .tech-icon {
    width: 80px;
    margin: 12px;
  }
}

@media (max-width:1600px) {
  .project-card.expanded {
    .project-info h3 {
      font-size: 5.5rem;
    }
    project-types span {
      font-size: 1.8rem;
    }
    .project-details {
      width: 70%;
    }
    .container-img-project-card {
      width: 70%;
    }
  }
  .arrow-left {
    width: 160px;
    height: 160px;
    left: 6vw;
  }
}

@media (max-width:1200px) {
  .container-img-project-card {
    width: 400px;
    height: 200px;
  }
  .project-card:hover {
    .container-img-project-card {
      width: 380px;
    }
  }
  .project-card.left .project-info {
    margin-left: 36px;
  }
  .project-card.right .project-info {
    margin-right: 36px;
  }
  .project-card.expanded {
    .project-info h3 {
      font-size: 5rem;
    }
    .container-img-project-card {
      width: 80%;
      height: 400px;
    }
  }
  .project-card.expanded .project-types span {
    font-size: 1.6rem;
  }
  .project-details {
    width: 80%;
  }
}

@media (max-width:992px) {
  .container-img-project-card {
    width: 320px;
    height: 160px;
  }
  .project-card:hover {
    .container-img-project-card {
      width: 300px;
    }
  }
  .project-card.left .project-info {
    margin-left: 20px;
  }
  .project-card.right .project-info {
    margin-right: 20px;
  }
  .project-card.expanded {
    .project-info h3 {
      font-size: 4.3rem;
    }
    .project-types span {
      font-size: 1.4rem;
    }
    .container-img-project-card {
      width: 80%;
      height: 320px;
    }
  }
  .arrow-left {
    width: 120px;
    height: 120px;
    top: 2vh;
  }
}

@media (max-width:768px) {

}

@media (max-width:576px) {

}

@media (max-width:480px) {

}

@media (max-width:360px) {

}
