header {
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 5;
  transition: top 0.6s, background-color 0.3s;
  background-color: transparent;
  max-width: 1800px;

  &.hidden {
    top: -100%;
  }

  .hamburger-icon {
    padding: 16px;
    display: none;
    position: absolute;
    z-index: 3;
    top: 24px;
    right: 8px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;

    .icon {
      cursor: inherit;
      display: block;
      width: 30px;
      height: 2px;
      background-color: black;
      position: relative;

      &::before,
      &::after {
        content: '';
        cursor: inherit;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }

      &::before {
        top: -10px;
        transform-origin: left;
      }
      &::after {
        top: 10px;
        transform-origin: left;
      }

      &.icon-x {
        background-color: transparent; // Fade out the middle line

        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 24px 0px 24px 48px;
    margin: 0;
    display: flex; // For larger screens

    li {
      display: inline;
      cursor: pointer;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 2rem;
      font-weight: 600;
      text-decoration: none;
      color: black;
      margin-right: 80px;
      position: relative;

      &:hover {
        color: #7DC6CC;
      }

      &.active:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #7DC6CC;
      }
    }
  }

  &.blue-bg {
    // background-color: rgba(255, 255, 255, 0.9);

    ul li.active:after {
      background-color: #FCFCFC;
    }

    ul li:hover {
      color: #FCFCFC;
    }
  }

  &.black-bg {
    .hamburger-icon .icon {
      background-color: #FCFCFC;
      &::before,
      &::after {
        background-color: #FCFCFC;
      }
      &.icon-x {
        background-color: transparent;
      }
    }

    ul li {
      color: #FCFCFC;
    }

    ul li.active:after {
      background-color: #7DC6CC;
    }

    ul li:hover {
      color: #7DC6CC;
    }
  }
}

@media (max-width: 1600px) {
  header ul {
    padding: 16px 0px 16px 48px;
    li{
      font-size: 1.6rem;
    }
  }
}

@media (max-width: 768px) {
  header {
    padding: 32px 0px 32px 0px;
    .hamburger-icon {
      display: block;
    }

    nav {
      position: fixed;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgb(102, 160, 165);
      // background-color: #F05B2A;
      // transform: translateX(-110%) rotate(10deg);
      // transform-origin: top left;
      // transition: height 0.3s ease-in-out;
      // text-align: center;
      padding-left: 48px;
      // justify-content: center;
      display: none;
      align-items: center;
      // animation-iteration-count: 1;
      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        margin-left: 48px;

        li {
          display: block;
          margin-bottom: 20px;
          color: #FCFCFC; // Set text color to white for visibility
          &:hover {
            color: #FCFCFC; // Set hover color to white for visibility
          }

          &.active::after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #FCFCFC; // Set underline to white
          }
        }
      }
    }

    nav.open {
      animation-name: expand-circle;
      animation-duration: 1s;
      // animation-iteration-count: 1;
    }
    nav.close {
      animation-name: collapse-circle;
      animation-duration: 1s;
    }

    &.black-bg {
      // background-color: rgba(255, 255, 255, 0.9);
      ul li {
        color: #FCFCFC;
      }

      ul li.active:after {
        background-color: #FCFCFC;
      }

      ul li:hover {
        color: #FCFCFC;
      }
    }
  }
}

@keyframes expand-circle {
  0% {clip-path: circle(0% at 150% 0%);}
  100% {clip-path: circle(150%);}
}

@keyframes collapse-circle {
  0% {clip-path: circle(150%);}
  100% {clip-path: circle(0% at 150% 0%);}
}


// @media (min-height: 1024px) {
//   header {
//     padding-top: 24px;
//   }
// }
