#home {
  padding: 0 48px 24px 48px;
  overflow: hidden;
}

#container-home {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16vh;
  height: fit-content;
}

#home-headers {
  max-width: 50%;
  white-space: nowrap;
}

#container-home-img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 75%;
  img {
    width: 50%;
  }
}

#container-home-info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4vh;
}

#container-home-info-text {
  max-width: 50%;
  p {
    opacity: 0;
  }
}

@media (min-width: 1600px) {
  #container-home-img {
    max-height: 55vh;
  }
}

@media (max-width: 1600px) {
  #container-home-img {
    img {
      width: 52%;
      margin-top: -16vh;
    }
  }
}

@media (max-width:1200px) {
  #container-home-img {
    img {
      width: 56%;
    }
  }

}

@media (max-width:992px) {
  #container-home {
    height: 90vh;
    padding: 48px 0px;
  }

  #container-home-img {
    img {
      width: 70%;
    }
  }
  #container-home-info-text {
    max-width: 70%;
  }
}

@media (max-width:768px) {
  #container-home {
    margin-bottom: 4vh;
  }
  #container-home-img {
    img {
      width: 96%;
      position: relative;
      right: -100px;
      margin-bottom: 0;
    }
  }
  #container-home-info-text {
    max-width: 80%;
  }
}

@media (max-width:576px) {
  #container-home-img {
    img {
      width: 110%;
    }
  }
  #container-home-info-text {
    max-width: 90%;
  }
}

@media (max-width:480px) {
  #home {
    padding: 24px 16px;
  }
  #container-home-img {
    img {
      width: 120%;
      right: -80px;
    }
  }
}

@media (max-height: 480px) {
  #container-home-img img {
    width: 56%;
  }
}
