.accordion {
  width: 100%;
  border: 2px solid #fcfcfc64;
  border-radius: 20px;
  margin-bottom: 20px;
  // padding: 16px 48px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  &:hover {
    border: 2px solid #fcfcfc;
  }
}

.accordion-header {
  margin: 16px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    color: #fcfcfc;
    font-size: 1.4rem;
  }
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
  margin: 0 48px;
  &.open {
    max-height: 2000px; // Adjust this if you know the height or use max-height for a smoother transition
  }
}

@media (max-width: 1600px) {
  .accordion-header {
    margin: 16px 24px;
  }
  .accordion-content {
    margin: 0 24px;
  }
}

@media (max-width: 1200px) {
  .accordion-header {
    svg {
      font-size: 1.3rem;
    }
  }
}

@media (max-width: 768px) {
  .accordion-header {
    svg {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 576px) {
  .accordion-header {
    svg {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 480px) {
  .accordion-header {
    svg {
      font-size: 1rem;
    }
  }
}
