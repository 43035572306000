#contact {
  padding: 0 48px;
  min-height: 100vh;
  a {
    text-decoration: underline;
    color: black;
    transition: color 0.3s ease;
    &.subheader-md {
      font-weight: 550;
    }
    &:hover  {
      color: #7DC6CC;
    }
  }
}

#container-contact {
  width: 100%;
  display: flex;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.img-rolling-ball {
  position: relative;
  z-index: 0;
  width: 50vw;
  height: 50vw;
  max-width: 800px;
  max-height: 800px;
  border-radius: 50%;
  shape-outside: circle();
  background-position: center;
  background-size: cover;
}

#container-contact-form {
  position: relative;
  z-index: 1;
  margin-left: -8%;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

form {
  width: 100%;
}

#contact-container-form-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.floating-input {
  position: relative;
  margin-bottom: 20px;
  width: 100%;

  input {
    width: 100%;
    padding: 16px;
    background: none;
    border: none;
    border-bottom: 2px solid black;
    margin-top: 16px;

    &:focus {
      outline: none;
      border-bottom: 2px solid black;
      color: black;
    }

    &:focus + label, &:not(:placeholder-shown) + label {
      transform: translateY(-30px);
      font-size: 0.8rem;
      color: black;
    }
  }

  textarea {
    width: 100%;
    height: 240px;
    padding: 16px;
    background: #fcfcfc78;
    border: 2px solid black;
    margin-top: 16px;

    &::placeholder {
      color: black;
      font-family: 'Source Sans Pro';
      font-weight: 400;
    }

    &:focus {
      outline: none;
      border: 2px solid black;
      color: black;
      &::placeholder {
        color: black;
      }
    }

    &:focus + label, &:not(:placeholder-shown) + label {
      transform: translateY(-30px);
      font-size: 0.8rem;
      color: black;
    }


  }

  label {
    position: absolute;
    top: 36px;
    font-weight: 400;
    left: 0;
    color: black;
    transition: all 0.3s ease;
    pointer-events: none;
    font-family: 'Source Sans Pro';
    font-size: 1.6rem;
  }
}


.field-error {
  color: #dc3232;
  font-size: 1rem;
  text-align: left;
  font-family: 'Source Sans Pro';
  font-weight: 400;
}

@media (max-width:1600px) {
  .img-rolling-ball {
    width: 44vw;
    height: 44vw;
  }
  #container-contact-form {
    width: 50%;
    margin-left: -2%;
  }
  .floating-input {
    label, ::placeholder {
      font-size: 1.4rem;
    }
  }
}

@media (max-width:1200px) {
  .img-rolling-ball {
    width: 40vw;
    height: 40vw;
  }
  #container-contact-form {
    width: 50%;
    margin-left: -2%;
  }
  .floating-input {
    label, ::placeholder {
      font-size: 1.3rem;
    }
  }
}

@media (max-width:992px) {
  .floating-input {
    textarea {
      width: 180%;
      margin-left: -81%;
    }
  }
  form {
   .btn-black-rounded {
      margin-left: -72%;
   }
  }
  .floating-input {
    label, ::placeholder {
      font-size: 1.2rem;
    }
  }
}

@media (max-width:700px) {
  .img-rolling-ball {
    margin-top: 4%;
  }
  .floating-input {
    textarea {
      width: 186%;
      margin-left: -87%;
    }
  }
}

@media (max-width:576px) {
  .img-rolling-ball {
    width: 60vw;
    height: 60vw;
    opacity: 0.3;
    margin-left: 28%;
    margin-top: -4%;
    position: absolute;
    z-index: 1;
  }
  .floating-input {
    label, ::placeholder {
      font-size: 1.1rem;
    }
  }
  #container-contact-form {
    width: 94%;
    margin-left: 0;
  }
  #contact-container-form-btn {
    width: 110%;
  }
  .floating-input {
    textarea {
      width: 100%;
      margin-left: 0;
    }
  }
  form {
    order: 1;
    .btn-black-rounded {
       margin-left: 0;
    }
   }
   #contact {
    padding: 0 36px;
    .header-lg {
      font-size: 5rem;
    }
   }
}

@media (max-width:480px) {
  #contact {
    padding: 24px 16px;
    .header-lg {
      font-size: 3.8rem;
    }
  }
  #container-contact-form {
    width: 90%;
  }
  #contact-container-form-btn {
    width: 110%;
  }

}

@media (max-width:360px) {

}
