@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-left {
  opacity: 0;
  animation: slideInLeft 1s ease forwards;
}

.slide-in-right {
  opacity: 0;
  animation: slideInRight 1s ease forwards;
}

.slide-in-bottom {
  opacity: 0;
  animation: slideInBottom 1s ease forwards;
}
